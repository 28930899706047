.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input {
  width: 70%;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  font-size: 18px;
}

button {
  width: 30%;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  border: none;
  outline: none;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #3e8e41;
}

p {
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: 18px;
}

.todo-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.todo-item button {
  margin-left: 10px;
  min-width: 80px;
  height: 30px;
  border: none;
  background-color: #dc3545;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.todo-item button:hover {
  background-color: #c82333;
}
